import React, { useState, useEffect } from 'react';
import { Switch, Case, Default } from 'react-if';
import {
  Container,
  Box,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import md from '../../../lib/md';
import HTML from '../HTML';

const RootContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
});

export default function SLA() {
  const [html, setHTML] = useState();

  useEffect(() => {
    import(
      /* webpackMode: "lazy" */
      './addon_terms.md'
    )
      .then(({ default: content }) => {
        setHTML(md.render(content));
      });
  }, []);
  return (

    <RootContainer maxWidth="md">
      <Switch>
        <Case condition={!html}>
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        </Case>
        <Default>
          <HTML
            withPrintButton
            html={html}
          />
        </Default>
      </Switch>

    </RootContainer>

  );
}
