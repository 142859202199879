import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {
  Typography,
  Box,
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
  Divider,
  Link,
} from '@mui/material';
import emailAddresses from 'email-addresses';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import urls from '../../shared/urls';
import requestWithAuth from '../../utils/requestWithAuth';

const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '5%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10vh',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
  },
  cardInner: {
    width: '400px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardInfo: {
    width: '90%',
    minHeigth: '100px',
  },
  header: {
    color: 'grey',
    margin: theme.spacing(2),
    marginTop: 0,
  },
  inputFields: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  mainArea: {
    minWidth: '400px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
}));

function Reset() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [highlightError, setHighlightError] = useState(false);

  const isEmailValid = !_.isEmpty(email) && !!emailAddresses.parseOneAddress(email);

  const handleChangeEmail = (ev) => {
    setEmail(ev.target.value);
  };

  const isDataValid = (emailValue) => {
    const isValid = !_.isEmpty(emailValue)
            && !!emailAddresses.parseOneAddress(emailValue);

    if (!isValid) {
      setHighlightError(true);
      return false;
    }
    return true;
  };

  const onSendRecovery = () => {
    if (!isDataValid(email)) {
      return;
    }

    requestWithAuth.post(urls.recovery, {
      email,
    }).then(() => {
      navigate('/reset-email-info');
    }).catch((err) => {
      let message = '';

      if (!err.request) {
        message = 'Что-то пошло не так, но мы уже получили об этом информацию и работаем над этим';
      } else if (!err.response) {
        message = 'Возникла проблема с обработкой вашего запроса. Проверьте подключение к интернету.';
      } else if (err.response.data?.message) {
        message = err.response.data?.message;
      } else if (err.response.status === 429) {
        message = 'Слишком много попыток запросов на смену пароля. Пожалуйста, попробуйте позже';
      } else {
        message = 'Произошла ошибка при отправке письма на email';
      }

      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
        action: (
          <IconButton
            onClick={() => {
              closeSnackbar();
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      });
    });
  };

  return (
    <Box
      className={classes.mainBoxWrapper}
    >
      <div className={classes.mainArea}>
        <Card className={classes.cardInner}>
          <CardContent className={classes.cardInfo}>
            <div className={classes.header}>
              <Typography variant="h5" gutterBottom>
                Восстановление пароля
              </Typography>
            </div>
            <Divider />
            <TextField
              variant="outlined"
              label="Email"
              margin="normal"
              onChange={handleChangeEmail}
              className={classes.inputFields}
              required
              error={!isEmailValid && highlightError}
            />
            <Box className={classes.buttonsWrapper}>
              <Button
                variant="outlined"
                onClick={onSendRecovery}
                className={classes.buttons}
                disabled={!isEmailValid}
              >
                Восстановить пароль
              </Button>
            </Box>
          </CardContent>
        </Card>
        <div className={classes.linksWrapper}>
          <Link
            href="/"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            Обратно в developers
          </Link>
          <Link
            href="/login"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            На страницу авторизации
          </Link>
        </div>
      </div>
    </Box>
  );
}

export default Reset;
