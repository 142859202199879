/* globals window */
import { useLayoutEffect, useState } from 'react';
import bowser from 'bowser';

function useIsMobileOrTablet() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useLayoutEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    const platform = browser.getPlatform().type;
    setIsMobileOrTablet(platform === 'tablet' || platform === 'mobile');
  }, []);

  return isMobileOrTablet;
}

export default useIsMobileOrTablet;
