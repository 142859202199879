import React from 'react';
import {
  Typography,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import hljs from 'highlight.js';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import Footer from '../../Footer';
import {
  Wrapper,
  SectionTitleWrapper,
  StyledTable,
  TableCellContent,
  Caption,
} from '../../../lib/Components/StyledComponents';
import cardButtonsImg from '../../../images/card_buttons.png';

const example = `'card_buttons': (cardButtonsContext) => {
  return [{
    text: 'Test button 1',
    callback: async (callbackContext, callbackOptions) => {
      console.log('card test button 1 clicked, i will fetch card and simply console log it');
      try {
        const card = await callbackContext.getCard();
        console.log('here is card title: ', card.title);
      } catch (err) {
        console.log('error while fetching card');
      }
    }
  }];
},`;

function CardButtons() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <div>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title="card_buttons" hash="#header" />
          </SectionTitleWrapper>
          <Typography gutterBottom sx={{ marginBotton: 2 }}>
            Actions/buttons of the addon that will be displayed in the &apos;Addon Actions&apos; section of the card.
          </Typography>
          <img width="600" src={cardButtonsImg} alt="card buttons" />
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            <b>Function argument</b>
            {' '}
            - an object that provides all available functions of the web SDK.
          </Typography>
          <Typography gutterBottom sx={{ marginTop: 2, marginBottom: 2 }}>
            <b>Return value</b>
            {' '}
            - the function should return array of objects with the following keys:
          </Typography>
          <Paper elevation={0}>
            <TableContainer>
              <StyledTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCellContent>Key</TableCellContent>
                    <TableCellContent>Type</TableCellContent>
                    <TableCellContent>Description</TableCellContent>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellContent>
                      <b>text</b>
                      <Caption
                        variant="caption"
                        color="error"
                      >
                        required
                      </Caption>
                    </TableCellContent>
                    <TableCellContent>string</TableCellContent>
                    <TableCellContent>Button text</TableCellContent>
                  </TableRow>
                  <TableRow>
                    <TableCellContent>
                      callback
                    </TableCellContent>
                    <TableCellContent>function</TableCellContent>
                    <TableCellContent>
                      The function that will be called upon clicking the button.
                    </TableCellContent>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>

          <Typography gutterBottom sx={{ marginTop: 2 }}>
            This result will be used for displaying buttons.
          </Typography>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            Example :
          </Typography>
          <Snippet>
            <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
          </Snippet>
        </div>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default CardButtons;
