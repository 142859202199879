import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Footer from './Footer';

const Root = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

function WithFooter({ children }) {
  return (
    <Root>
      <Box>
        {children}
      </Box>
      <Footer />
    </Root>
  );
}

WithFooter.propTypes = {
  children: PropTypes.any.isRequired,
};

export default WithFooter;
