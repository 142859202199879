import {
  Box,
  Table,
  TableCell,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const SectionTitleWrapper = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

const StyledTable = styled(Table)(
  () => ({
    minWidth: 600,
  }),
);

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const Caption = styled(Typography)({
  display: 'block',
});

const InnerSchemaWrapper = styled(Box)(
  ({ theme }) => ({
    marginLeft: theme.spacing(-1),
  }),
);

export {
  Wrapper,
  SectionTitleWrapper,
  StyledTable,
  TableHeader,
  TableCellContent,
  Caption,
  InnerSchemaWrapper,
};
