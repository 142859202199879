const urls = {
  getCompanyAddons: (id = '') => `/api/addons/${id}`,
  signup: '/signup',
  login: '/login',
  logout: '/logout',
  activate: '/activate',
  resend: '/resend-email-verification',
  recovery: '/send-recovery',
  reset: '/update-password',
};

export default urls;
