import React from 'react';
import {
  Typography,
  Alert,
  Link as MuiLink,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Footer from '../../Footer';
import {
  Wrapper,
  SectionTitleWrapper,
} from '../../../lib/Components/StyledComponents';
import FunctionContent from './FunctionContent';
import iframePopupImg from '../../../images/iframe_popup.png';
import confirmPopupImg from '../../../images/confirm_popup.png';
import staticListPopupImg from '../../../images/static_list_popup.png';
import dynamicListPopupImg from '../../../images/dynamic_list_popup.png';
import nestedPopupImg from '../../../images/nested_popup.png';

const iframePopupSnippet = ` await settingsContext.openPopup({
  type: 'iframe',
  title: 'Timer settings',
  url: './settings.html',
  height: 200,
  width: 300
});`;

const confirmPopupSnippet = `btnContext.openPopup({
  type: 'confirm',
  title: 'Confirm popup',
  text: 'Are you sure? :)',
  confirmLabel: 'Yes, do it',
  confirmCallback: (popupContext) => {
    console.log('confirm clicked!');
  },
  cancelLabel: 'Cancel',
  cancelCallback: () => {
    console.log('cancel clicked!');
  }
});`;

const staticListSnippet = `context.openPopup({
  type: 'staticList',
  title: 'Test static list',
  items: [{
    text: 'Open test iframe popup',
    secondaryText: 'It simply demonstrates iframe popup in current popup',
    callback: (btnContext) => {...}
  }, {
    text: 'Open submit popup',
    secondaryText: 'Submit popup example',
    callback: (btnContext) => {...}
  }, {
    text: 'Simple list with search',
    secondaryText: 'Demonstrates simple list with search',
    callback: (btnContext) => {...}
  }, {
    text: 'Open dynamic search popup',
    secondaryText: 'Demonstrates dynamic search',
    callback: (btnContext) => {...}
  }]
})`;

const dynamicListSnippet = `btnContext.openPopup({
  type: 'dynamicList',
  title: 'Dynamic list',
  loadingLabel: 'Searching options...',
  items: async (context, options) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 1000);
    });
    if (!options.data.searchValue.length) {
      return [];
    }

    return generateArrayWithObjects(20, options.data.searchValue); 
  },
  search: {
    emptyLabel: 'Type something to get options',
    enabled: true,
    debounce: 400,
  }
});`;

const popupData = {
  iframe: {
    description: {
      head: 'context.openPopup(params)',
      text: ' - Opens a popup with an iframe inside.',
    },
    returnValue: {
      type: 'Promise',
    },
    arguments: [
      {
        name: 'params',
        type: 'object',
        description: 'params for popup',
        properties: {
          type: {
            enum: ['iframe'],
          },
          title: {
            type: 'string',
            description: 'Popup title',
          },
          url: {
            type: 'string',
            description: 'Url popup iframe',
          },
          height: {
            type: 'number',
            description: 'Popup height',
          },
          width: {
            type: 'number',
            description: 'Popup width',
          },
        },
        required: ['type', 'url'],
      },
    ],
    images: [
      {
        src: iframePopupImg,
        width: 500,
        alt: 'iframe popup',
      },
    ],
    snippet: {
      lng: 'javascript',
      content: iframePopupSnippet,
    },
  },
  confirm: {
    description: {
      head: 'context.openPopup(params)',
      text: ' - Opens a confirm popup.',
    },
    returnValue: {
      type: 'Promise',
    },
    arguments: [
      {
        name: 'params',
        type: 'object',
        description: 'params for popup',
        properties: {
          type: {
            enum: ['confirm'],
          },
          title: {
            type: 'string',
            description: 'Popup title',
          },
          text: {
            type: 'string',
            description: 'Confirm text',
          },
          confirmLabel: {
            type: 'string',
            description: 'Confirm button text',
          },
          confirmCallback: {
            type: 'function',
            description: 'The function that will be called when the confirmation button is pressed.',
          },
          cancelLabel: {
            type: 'string',
            description: 'Cancel button text',
          },
          cancelCallback: {
            type: 'function',
            description: 'If this callback is passed, a button will be rendered. Clicking on this button will trigger the callback.',
          },
        },
        required: ['type', 'text', 'confirmCallback'],
      },
    ],
    images: [
      {
        src: confirmPopupImg,
        width: 500,
        alt: 'confirm popup',
      },
    ],
    snippet: {
      lng: 'javascript',
      content: confirmPopupSnippet,
    },
  },
  staticList: {
    description: {
      head: 'context.openPopup(params)',
      text: " - It opens a popup with a list type. It displays a list of options. Each option has text and a callback function that will be triggered when the option is clicked. There's also an option to display a text field for filtering options (in case the number of options is large).",
    },
    returnValue: {
      type: 'Promise',
    },
    arguments: [
      {
        name: 'params',
        type: 'object',
        description: 'params for popup',
        properties: {
          type: {
            enum: ['staticList'],
          },
          title: {
            type: 'string',
            description: 'Popup title',
          },
          items: {
            type: 'array',
            description: 'Options list',
            items: {
              type: 'object',
              properties: {
                text: {
                  type: 'string',
                  description: 'Main text',
                },
                secondaryText: {
                  type: 'string',
                  description: 'Secondary text',
                },
                callback: {
                  type: 'function',
                  description: 'The function that will be executed when the element is clicked. Either a url or a callback must be defined.',
                },
                url: {
                  type: 'string',
                  description: 'The address that will be opened in a new window when the button is clicked. Either a url or a callback must be defined.',
                },
              },
              required: ['text'],
            },
          },
          search: {
            type: 'object',
            properties: {
              enabled: {
                type: 'boolean',
                description: 'If true, a search field will be displayed above the list, allowing filtering options by "text" match.',
              },
              emptyLabel: {
                type: 'string',
                description: 'The text to be displayed in case of an empty list of options.',
              },
            },
          },
        },
        required: ['type', 'items'],
      },
    ],
    images: [
      {
        src: staticListPopupImg,
        width: 500,
        alt: 'static list popup',
      },
    ],
    snippet: {
      lng: 'javascript',
      content: staticListSnippet,
    },
  },
  dynamicList: {
    description: {
      head: 'context.openPopup(params)',
      text: ' - It opens a popup with a list type and a search field. Entering data into the field triggers a function, the result of which should be a list of available options based on the search phrase..',
    },
    returnValue: {
      type: 'Promise',
    },
    arguments: [
      {
        name: 'params',
        type: 'object',
        description: 'params for popup',
        properties: {
          type: {
            enum: ['dynamicList'],
          },
          title: {
            type: 'string',
            description: 'Popup title',
          },
          loadingLabel: {
            type: 'string',
            description: 'The text to be displayed while the search is being performed.',
          },
          items: {
            type: 'function',
            description: 'The function that is called with two arguments: context and options \n\nThe search value is available in "options.data.searchValue".\n\nThe function should return an array, where each element is an object with the following keys:\n  "text" (string): Required. The main text of the element.\n  "secondaryText" (string): Optional. The secondary text of the element\n  "callback" (function): The function to be executed when the element is clicked.\n  "url" (string): The address to be opened when the element is clicked.\n\nEither "callback" or "url" must be provided for each element.',
          },
          search: {
            type: 'object',
            properties: {
              enabled: {
                type: 'boolean',
                description: 'If true, a search field will be displayed above the list, allowing filtering options by "text" match.',
              },
              emptyLabel: {
                type: 'string',
                description: 'The text to be displayed in case of an empty list of options.',
              },
              debounce: {
                type: 'number',
                description: 'The delay in ms after which the items function will be called. (Default debounce is 500 ms)',
              },
            },
          },
        },
        required: ['type', 'items'],
      },
    ],
    images: [
      {
        src: dynamicListPopupImg,
        width: 500,
        alt: 'dynamic list popup',
      },
    ],
    snippet: {
      lng: 'javascript',
      content: dynamicListSnippet,
    },
  },
};

function openPopup() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <div>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title="openPopup" hash="#header" />
          </SectionTitleWrapper>
          <Typography gutterBottom sx={{ marginBottom: 2 }}>
            The following types of popups are supported:
          </Typography>
          <ul>
            <li>
              <MuiLink color="secondary" component={RouterLink} to="/addons/web-sdk-ui-functions/open-popup#iframe">
                iframe
              </MuiLink>
            </li>
            <li>
              <MuiLink color="secondary" component={RouterLink} to="/addons/web-sdk-ui-functions/open-popup#confirm">
                confirm
              </MuiLink>
            </li>
            <li>
              <MuiLink color="secondary" component={RouterLink} to="/addons/web-sdk-ui-functions/open-popup#staticList">
                staticList
              </MuiLink>
            </li>
            <li>
              <MuiLink color="secondary" component={RouterLink} to="/addons/web-sdk-ui-functions/open-popup#dynamicList">
                dynamicList
              </MuiLink>
            </li>
          </ul>
          <SectionTitleWrapper>
            <SectionTitle shouldScrollOnHashUpdate variant="h5" title="iframe popup" hash="#iframe" />
          </SectionTitleWrapper>
          <FunctionContent data={popupData.iframe} />
          <SectionTitleWrapper sx={{ marginTop: 2 }}>
            <SectionTitle shouldScrollOnHashUpdate variant="h5" title="confirm popup" hash="#confirm" />
          </SectionTitleWrapper>
          <FunctionContent data={popupData.confirm} />
          <SectionTitleWrapper sx={{ marginTop: 2 }}>
            <SectionTitle shouldScrollOnHashUpdate variant="h5" title="staticList popup" hash="#staticList" />
          </SectionTitleWrapper>
          <FunctionContent data={popupData.staticList} />
          <SectionTitleWrapper sx={{ marginTop: 2 }}>
            <SectionTitle shouldScrollOnHashUpdate variant="h5" title="dynamicList popup" hash="#dynamicList" />
          </SectionTitleWrapper>
          <FunctionContent data={popupData.dynamicList} />
          <SectionTitleWrapper sx={{ marginTop: 2 }}>
            <SectionTitle variant="h5" title="Opening nested popups" hash="#nested" />
          </SectionTitleWrapper>
          <Alert sx={{ marginBottom: 2 }} severity="info">
            If you open a new popup within the current popup,
            the content of the new popup will replace the current content,
            and a back button will be displayed to return to the previous popup
            (for example, if you click on an item in the list to confirm, it will provide a familiar user experience of selection with confirmation).
          </Alert>
          <img width="500" src={nestedPopupImg} alt="nested popup" />
        </div>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default openPopup;
