import React from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Link,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '5%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10vh',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
  },
  header: {
    color: 'grey',
    margin: theme.spacing(2),
    marginTop: 0,
  },
  cardInner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardInfo: {
    maxWidth: '300px',
    minHeigth: '100px',
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  marginWrapper: {
    margin: theme.spacing(2),
  },
  mainArea: {
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWidth: '90%',
    },
  },
}));

function CheckEmailMessageResetMessage() {
  const classes = useStyles();

  return (
    <Box
      className={classes.mainBoxWrapper}
    >
      <div className={classes.mainArea}>
        <Card className={classes.cardInner}>
          <CardContent className={classes.cardInfo}>
            <div className={classes.header}>
              <Typography variant="h6">
                Восстановление пароля
              </Typography>
            </div>
            <Divider />
            <div className={classes.marginWrapper}>
              <Typography variant="h7">
                Инструкция по восстановлению пароля отправлено к Вам на почту
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Divider />
        <div className={`${classes.linksWrapper}`}>
          <Link
            href="/"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            Обратно в developers
          </Link>
          <Link
            href="/login"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            На страницу авторизации
          </Link>
        </div>
      </div>
    </Box>
  );
}

export default CheckEmailMessageResetMessage;
