import _ from 'lodash';

const initialState = {
  name: '',
  iframe_initial_url: '',
  is_processing: false,
  capabilities: [],
  icon_url: '',
  overview: '',
  description: '',
  domain: '',
};

export const actionTypes = {
  updateName: 'updateName',
  updateInitialUrl: 'updateInitialUrl',
  setProcessing: 'setProcessing',
  finishProcessing: 'finishProcessing',
  toggleCapability: 'toggleCapability',
  updateIconUrl: 'updateIconUrl',
  updateDescription: 'updateDescription',
  updateOverview: 'updateOverview',
  updateTestDomain: 'updateTestDomain',
};

export function getInitialState(plugin = null) {
  if (!plugin) {
    return initialState;
  }

  return {
    ..._.pick(plugin, _.keys(initialState)),
  };
}

export default function reducer(state, action) {
  switch (action.type) {
    case actionTypes.updateName:
      return { ...state, name: action.data };
    case actionTypes.updateInitialUrl:
      return { ...state, iframe_initial_url: action.data };
    case actionTypes.updateIconUrl:
      return { ...state, icon_url: action.data };
    case actionTypes.updateOverview:
      return { ...state, overview: action.data };
    case actionTypes.updateDescription:
      return { ...state, description: action.data };
    case actionTypes.updateTestDomain:
      return { ...state, domain: action.data };
    case actionTypes.setProcessing:
      return { ...state, is_processing: true };
    case actionTypes.finishProcessing:
      return { ...state, is_processing: false };
    case actionTypes.resetDirty:
      return { ...state, is_dirty: false };
    case actionTypes.toggleCapability: {
      const currentCapabilities = state.capabilities;
      const { capability } = action;
      const updatedCapabilities = _.includes(currentCapabilities, capability)
        ? _.reject(currentCapabilities, (currentCapability) => currentCapability === capability)
        : [...(currentCapabilities || []), capability];
      return { ...state, capabilities: updatedCapabilities };
    }
    default:
      throw new Error('Unknown action type!');
  }
}
