import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Root = styled('div')({
  height: '100vh',
  overflow: 'auto',
  '@media print': {
    overflow: 'visible',
  },
});

function Document({ children }) {
  return (
    <Root>
      {children}
    </Root>
  );
}

Document.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Document;
