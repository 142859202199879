import React from 'react';
import {
  Typography, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NativeScrollContainer from '../NativeScrollContainer';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);
const BoldText = styled(Typography)({
  marginLeft: '3px',
  marginRight: '3px',
  fontWeight: 'bold',
});

const StyledListItem = styled(Box)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

function Introduction() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          {`${projectName} universal imports`}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {`
            This specification outlines the process and requirements for importing data from other task management tools into ${projectName}.
            The import process is designed to integrate external data seamlessly into our system,
            ensuring that it can be processed accurately and effectively.
            ${projectName} supports importing data across multiple sections, including:
          `}
        </Typography>
        <Box component="ul">
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Boards*</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Cards*</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Columns*</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Comments</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Custom fields</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Card files</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Users</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Spaces</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Folders</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Documents*</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Document files</BoldText>
            </Typography>
          </StyledListItem>
        </Box>
        <Typography gutterBottom variant="subtitle1">
          <BoldText>* - Variants of imports.</BoldText>
          <StyledListItem component="li">
            <BoldText>Import Option 1: Documents Only</BoldText>
            {' '}
            The user can choose to import
            just the Documents section without needing to include Cards, Boards, or Columns. This
            import will function with only document-related data present in the meta-data.json file.
          </StyledListItem>
          <StyledListItem component="li">
            <BoldText>Import Option 2: Columns, Cards, and Boards</BoldText>
            {' '}
            If the user specifies
            the import of Columns, the system will also require both Cards and Boards to be included
            in the meta-data.json file. This logic applies similarly to importing Cards or Boards —
            if any one of these sections is selected, the other two must also be included.
          </StyledListItem>
          <StyledListItem component="li">
            <BoldText>Import Option 3: Everything</BoldText>
            {' '}
            The user can choose to import all
            sections: Documents, Cards, Boards, and Columns, in which case all must be present in
            the meta-data.json file.
          </StyledListItem>
          <BoldText>In summary, the system allows:</BoldText>
          <Box component="ol">
            <StyledListItem component="li">
              <BoldText>Importing Documents only.</BoldText>
            </StyledListItem>
            <StyledListItem component="li">
              <BoldText>
                {' '}
                Importing Columns, Cards, and Boards as a group (any of these requires all
                three to be included).
              </BoldText>
            </StyledListItem>
            <StyledListItem component="li">
              <BoldText>Importing everything (Documents, Cards, Boards, Columns).</BoldText>
            </StyledListItem>
          </Box>
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {`
            The import process begins with a file named meta-data.json, which acts as the entry point for the import.
            This file contains references to each of the sections mentioned above,
            with the value of each section being the relative path to its corresponding JSON file.
            These JSON files contain the detailed structure and required fields for each section,
            ensuring that the imported data is compatible with ${projectName}'s internal architecture.
            Once the meta-data.json file is processed, each section is handled separately based on its respective JSON schema.
            The schema definitions provide a clear guideline on the expected format and content,
            allowing for a smooth import and integration into the ${projectName} system.
          `}
        </Typography>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Introduction;
