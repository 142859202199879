import files from './files';
import appendix from './appendix';

const entities = [
  files,
  appendix,
];

const api = {
  name: 'Imports',
  type: 'Imports',
  disableSearch: false,
  defaultRoute: '/imports',
  overview: [
    {
      id: 1,
      name: 'Introduction',
      path: '/imports',
    },
    {
      id: 2,
      name: 'Examples',
      path: '/imports/examples',
    },
  ],
  entities,
};

export default api;
