export default {
  name: 'Entities',
  id: 2,
  operations: [
    {
      id: 3,
      name: 'Meta-data',
      fileName: 'meta-data',
    },
    {
      id: 4,
      name: 'Boards',
      fileName: 'boards',
    },
    {
      id: 5,
      name: 'Cards',
      fileName: 'cards',
    },
    {
      id: 6,
      name: 'Columns',
      fileName: 'columns',
    },
    {
      id: 7,
      name: 'Comments',
      fileName: 'comments',
    },
    {
      id: 8,
      name: 'Custom fields',
      fileName: 'custom-fields',
    },
    {
      id: 9,
      name: 'Files',
      fileName: 'files',
    },
    {
      id: 12,
      name: 'Folders',
      fileName: 'folders',
    },
    {
      id: 13,
      name: 'Spaces',
      fileName: 'spaces',
    },
    {
      id: 11,
      name: 'Users',
      fileName: 'users',
    },
    {
      id: 14,
      name: 'Documents',
      fileName: 'documents',
    },
    {
      id: 15,
      name: 'Document Files',
      fileName: 'document-files',
    },
  ],
};
