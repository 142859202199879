import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Typography,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledControl = styled(FormControlLabel)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
  }),
);

function CompanyPluginCapabilities(props) {
  const {
    capabilities,
    toggleCapability,
    capabilityVariants,
  } = props;

  const handleChange = (e) => {
    const currentCapability = e.target.name;
    toggleCapability(currentCapability);
  };

  return (
    <div>
      {_.map(capabilityVariants, (capability) => (
        <StyledControl
          key={capability.key}
          value={capability.key}
          control={(
            <Switch
              checked={_.includes(capabilities, capability.key)}
              onChange={handleChange}
              name={capability.key}
              color="primary"
            />
          )}
          label={(
            <>
              <Typography variant="body2">
                {capability.title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {capability.description}
              </Typography>
            </>
          )}
        />
      ))}
    </div>
  );
}

CompanyPluginCapabilities.propTypes = {
  capabilities: PropTypes.array.isRequired,
  toggleCapability: PropTypes.func.isRequired,
  capabilityVariants: PropTypes.array.isRequired,
};

export default CompanyPluginCapabilities;
