/* globals window */

import React from 'react';
import PropTypes from 'prop-types';
import { If } from 'react-if';
import {
  Button,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const PrintButton = styled(Button)({
  alignSelf: 'flex-end',
  '@media print': {
    display: 'none',
  },
});

const HTMLContainer = styled('div')(({ theme }) => ({
  ...theme.typography,
  '& h1, h2, h3, h4, h5, h6': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  lineHeight: 1.45,
  visibility: 'visible!important',
  paddingBottom: theme.spacing(3),
  '& img': { maxWidth: '100%' },
  '& code': {
    whiteSpace: 'pre-wrap',
    display: 'inline-block',
    padding: '0 4px',
    fontSize: 14,
    borderRadius: 2,
    backgroundColor: 'rgba(105, 128, 234, 0.1)',
  },
  '& pre': {
    margin: theme.spacing(2, 0),
  },
  '& pre code': {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  '& table': {
    display: 'block',
    maxWidth: '100%',
    overflowX: 'auto',
    '& th, td': {
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& th': {
      ...theme.typography.subtitle2,
      padding: theme.spacing(1),
    },
  },

}));

function HTML({ html, withPrintButton }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handlePrintButtonClick = () => {
    window.print();
  };

  const goBack = () => {
    navigate(location.state?.from?.pathname ? -1 : '/');
  };

  return (
    <Root>
      <ButtonContainer>
        <Button
          color="secondary"
          startIcon={(<ArrowBackIosNewIcon />)}
          onClick={goBack}
        >
          { location.state?.from?.pathname ? 'Назад' : 'На главную'}
        </Button>
        <If condition={withPrintButton}>
          <PrintButton
            startIcon={(<PrintIcon />)}
            onClick={handlePrintButtonClick}
          >
            Распечатать
          </PrintButton>
        </If>
      </ButtonContainer>

      <HTMLContainer
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Root>
  );
}

HTML.propTypes = {
  html: PropTypes.string.isRequired,
  withPrintButton: PropTypes.bool,
};

HTML.defaultProps = {
  withPrintButton: false,
};

export default HTML;
