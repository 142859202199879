import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import NativeScrollContainer from '../NativeScrollContainer';
import Footer from '../Footer';
import generateJsxTable, { generateDetailedDescriptions } from './convertJsonToJsx';

const metaData = require('kaiten-lib/lib/validation/schemas/imports/meta-data');
const boards = require('kaiten-lib/lib/validation/schemas/imports/boards');
const cards = require('kaiten-lib/lib/validation/schemas/imports/cards');
const columns = require('kaiten-lib/lib/validation/schemas/imports/columns');
const comments = require('kaiten-lib/lib/validation/schemas/imports/comments');
const customFields = require('kaiten-lib/lib/validation/schemas/imports/custom-fields');
const files = require('kaiten-lib/lib/validation/schemas/imports/files');
const propertiesMapping = require('kaiten-lib/lib/validation/schemas/imports/properties-mapping');
const users = require('kaiten-lib/lib/validation/schemas/imports/users');
const spaces = require('kaiten-lib/lib/validation/schemas/imports/spaces');
const folders = require('kaiten-lib/lib/validation/schemas/imports/folders');
const documents = require('kaiten-lib/lib/validation/schemas/imports/documents');
const documentFiles = require('kaiten-lib/lib/validation/schemas/imports/document-files');

const jsonMapping = {
  'meta-data': metaData,
  boards,
  cards,
  columns,
  comments,
  'custom-fields': customFields,
  files,
  'properties-mapping': propertiesMapping,
  users,
  spaces,
  folders,
  documents,
  'document-files': documentFiles,
};

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

function Section({ title, children, id }) {
  return (
    <div id={id}>
      <Typography fontWeight="bold" marginTop={2}>
        {title}
      </Typography>
      {children}
    </div>
  );
}

function File({ operation }) {
  const json = jsonMapping[operation.fileName];
  const properties = json?.properties || json?.items?.properties;
  const required = json?.required || json?.items?.required || [];

  const customDefinitions = Object.fromEntries(
    Object.entries(json?.definitions || {}).filter(([, value]) => Object.hasOwn(value, 'properties')),
  );

  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          {json.title}
        </Typography>

        {json.description && (
        <Typography gutterBottom>
          <strong>Description:</strong>
          {' '}
          {json.description}
        </Typography>
        )}

        {properties && (
          <>
            <Section title="Fields:">
              {generateJsxTable(properties, required, Object.keys(customDefinitions))}
            </Section>

            <Section title="Description of fields:">
              {generateDetailedDescriptions(properties, json.notes)}
            </Section>

            {json.definitions
              && Object.entries(json.definitions).map(([key, definition]) => {
                const defProperties = definition?.properties || [];
                const defRequired = definition?.required || [];
                if (!defProperties || !Object.values(defProperties).length) return null;

                return (
                  <Section key={key} title={`${key}:`} id={key}>
                    {generateJsxTable(defProperties, defRequired, Object.keys(customDefinitions))}
                  </Section>
                );
              })}
          </>
        ) }
        <Section title="JSON schema:">
          <ReactJson
            src={json}
            name={false}
            enableClipboard={false}
            groupArraysAfterLength={0}
            displayObjectSize={false}
            displayDataTypes={false}
          />
        </Section>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

File.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
  }).isRequired,
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default File;
