import React from 'react';
import {
  Typography, Box, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NativeScrollContainer from '../NativeScrollContainer';

const importExample1 = require('../../importExamples/Kaiten_import_example.zip');
const importExample2 = require('../../importExamples/Kaiten_import_example_2.zip');
const importExample3 = require('../../importExamples/Kaiten_import_example_3.zip');

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const examplesArchives = [
  {
    id: 1,
    name: 'Company KPIs',
    path: importExample1,
  },
  {
    id: 2,
    name: 'Customer Feedbacks',
    path: importExample2,
  },
  {
    id: 3,
    name: 'Sales Growth Reports',
    path: importExample3,
  },
];

const handleExampleDownload = async (path, name) => {
  try {
    // Fetch the file to get its content as a Blob (Safari requires this for non-image assets)
    const response = await fetch(path);
    const blob = await response.blob();

    // eslint-disable-next-line
    const downloadLink = window.document.createElement('a');
    // eslint-disable-next-line
    const url = window.URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = name;

    // eslint-disable-next-line
    document.body.appendChild(downloadLink);
    downloadLink.click();
    // eslint-disable-next-line
    document.body.removeChild(downloadLink);

    // eslint-disable-next-line
    window.URL.revokeObjectURL(url);
  } catch (err) {
    // eslint-disable-next-line
    console.error('Error downloading the file:', err);
  }
};

function ImportsExamples() {
  return (
    <NativeScrollContainer className="examples">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          Examples
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          This section provides sample archives for users to download and explore the data import
          functionality.
          These examples contain dummy data, allowing you to test the import process and understand
          how the system works with data from other platforms.
        </Typography>
        {examplesArchives.map(({
          name,
          id,
          path,
        }) => (
          <Box sx={{ mb: 1 }} key={id}>
            <Button
              color="primary"
              variant="contained"
              component="button"
              onClick={() => handleExampleDownload(path, name)}
              href="#"
            >
              Example -
              {' '}
              {name}
            </Button>
          </Box>
        ))}
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default ImportsExamples;
