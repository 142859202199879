import React from 'react';
import {
  Typography,
} from '@mui/material';
import hljs from 'highlight.js';
import { styled } from '@mui/material/styles';
import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import Snippet from '../Snippet';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';
import createAddonImg1 from '../../images/addons_section_1.png';
import createAddonImg2 from '../../images/addons_section_2.png';
import createAddonImg3 from '../../images/addons_section_3.png';
import logInImg from '../../images/authorize.png';
import { Wrapper, SectionTitleWrapper } from '../../lib/Components/StyledComponents';

const getConnectExample = (hostName) => `<head>
    <meta charset="utf-8">
    <script src="https://files.${hostName}/web-sdk/v1.min.js"></script>
  </head>
`;

const ImageBox = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

function Intro() {
  const { projectName, hostName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title={`${projectName} Addons`} hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom variant="subtitle1">
          {`Addons allow you to expand functionality of ${projectName}`}
        </Typography>
        <Typography gutterBottom>
          You will need a server to host all the addon code.
          Addons require that resources be served over HTTPS.
          {` ${projectName} loads resources into iframes, so you need to update your CORS settings to allow `}
          {` ${projectName} to make requests to fetch your files`}
        </Typography>
        <Typography gutterBottom>
          {`Next, create the main html page that your server will serve, ${projectName} will load this page in a hidden iframe.`}
        </Typography>
        <Typography gutterBottom>
          In the head section, add our minified SDK
          {' ('}
          <a target="_blank" rel="noreferrer" href={`https://files.${hostName}/web-sdk/v1.min.js`}>
            {`https://files.${hostName}/web-sdk/v1.min.js`}
          </a>
          )
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(getConnectExample(hostName), { language: 'xml' }).value }} />
        </Snippet>
        <Typography gutterBottom>
          {`Next, go to ${projectName} Developers, log in to your account`}
        </Typography>
        <img width="100%" src={logInImg} alt="log in" />
        <StyledTypography gutterBottom>
          Next go to the section “Addons” and click on “Create a new addon”
        </StyledTypography>
        <img width="100%" src={createAddonImg1} alt="create addon 1" />
        <ImageBox>
          <img width="100%" src={createAddonImg2} alt="create addon 2" />
        </ImageBox>
        <Typography sx={{ marginTop: 1 }} gutterBottom>
          In the window that opens, enter the name, address of the Iframe connector url(the address where your server is running),
          the domain of the company where the addon will be available
          and mark the necessary features of our addon
        </Typography>
        <img width="400" src={createAddonImg3} alt="create addon" />
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Intro;
