import React from 'react';
import {
  Link, Box, Typography,
} from '@mui/material';
import { If } from 'react-if';
import { styled } from '@mui/material/styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get as registryGet } from '../../lib/appRegistry';

const LogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
}));

const DocumentLinkMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const DocumentLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1),
  '&&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
}));

function Footer({ withLinks }) {
  const {
    projectUrl, projectName, logoUrl,
  } = registryGet('config');

  return (
    <Wrapper>
      <LogoLink href={projectUrl}>
        <img src={logoUrl} className="logo-footer" alt="logo" />
        <Typography color="text.primary" variant="subtitle2">{projectName}</Typography>
      </LogoLink>
      <If condition={withLinks}>
        <DocumentLinkMenu>
          <DocumentLink
            href="/addon-terms"
            target="_blank"
          >
            Правила использования addon-ов
          </DocumentLink>
          <DocumentLink
            href="/personal-data-processing-policy"
            target="_blank"
          >
            Политика в отношении обработки персональных данных
          </DocumentLink>
        </DocumentLinkMenu>
      </If>
    </Wrapper>

  );
}

Footer.propTypes = {
  withLinks: PropTypes.bool,
};

Footer.defaultProps = {
  withLinks: false,
};

export default Footer;
