/* globals window */

import _ from 'lodash';
import hljs from 'highlight.js';
import markdownItContainer from 'markdown-it-container';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
  highlight: (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        const result = hljs.highlight(str, { language: lang });

        return result.value;
      } catch (__) {} // eslint-disable-line no-empty
    }

    return '';
  },
});

const linkOpenDefaultRender = md.renderer.rules.link_open
  || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

// Add target="_blank" to the external links
md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  const token = tokens[idx];
  const hrefIndex = token.attrIndex('href');
  const href = token.attrs[hrefIndex] && token.attrs[hrefIndex][1]
    ? token.attrs[hrefIndex][1]
    : null;
  const shouldAddTargetBlank = href
    && !_.startsWith(href, '/')
    && !_.startsWith(href, `${window.location.protocol}//${window.location.host}`);

  if (shouldAddTargetBlank) {
    const targetIndex = token.attrIndex('target');

    if (targetIndex < 0) {
      token.attrPush(['target', '_blank']);
    } else {
      token.attrs[idx][1] = '_blank';
    }
  }

  // pass token to default renderer.
  return linkOpenDefaultRender(tokens, idx, options, env, self);
};

md.use(markdownItContainer, 'container');

export default md;
