import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { If } from 'react-if';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  ListItemIcon,
} from '@mui/material';

import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import usePopover from '../hooks/usePopover';
import MenuItem from '../Menu/MenuItem';

const HoveredRow = styled(TableRow)(
  () => ({
    cursor: 'pointer',
  }),
);

const StyledIcon = styled(ListItemIcon)(
  () => ({
    minWidth: 35,
  }),
);

function AddonsTable(props) {
  const {
    addons,
    onAddonClick,
    onAddonRemove,
  } = props;

  const [activeAddonId, setActiveAddonId] = useState(null);
  const [isPopoverOpen, anchorEl, onPopoverOpen, onPopoverClose] = usePopover();

  const handleClick = (addonId) => () => {
    onAddonClick(addonId);
  };

  const handleMoreClick = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveAddonId(id);
    onPopoverOpen(e);
  };

  const handleCloseMorePopover = () => {
    setActiveAddonId(null);
    onPopoverClose();
  };

  const handleDeleteClick = () => {
    onAddonRemove(activeAddonId);
    handleCloseMorePopover();
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {_.map(addons, (addon) => (
            <HoveredRow
              hover
              key={addon.id}
              onClick={handleClick(addon.id)}
            >
              <TableCell>{addon.name}</TableCell>
              <TableCell>{_.capitalize(addon.status)}</TableCell>
              <TableCell>{moment(addon.created).format('LLL')}</TableCell>
              <TableCell>
                <If condition={addon.status !== 'published'}>
                  <IconButton size="small" onClick={handleMoreClick(addon.id)}>
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </If>
              </TableCell>
            </HoveredRow>
          ))}
        </TableBody>
      </Table>

      <If condition={isPopoverOpen && anchorEl}>
        <Menu
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onClose={handleCloseMorePopover}
        >
          <MenuItem
            dense
            onClick={handleDeleteClick}
            text="Delete"
            leftIcon={(
              <DeleteIcon fontSize="small" />
            )}
            iconComponent={StyledIcon}
          />
        </Menu>
      </If>
    </div>
  );
}

AddonsTable.propTypes = {
  addons: PropTypes.array.isRequired,
  onAddonClick: PropTypes.func.isRequired,
  onAddonRemove: PropTypes.func.isRequired,
};

export default AddonsTable;
