import {
  useCallback,
  useRef,
  useEffect,
} from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import useNotifier from '../hooks/useNotifier';
import urls from '../../shared/urls';
import { removeValue } from '../../utils/storage';

const pluginFields = [
  'name',
  'iframe_initial_url',
  'capabilities',
  'icon_url',
  'overview',
  'description',
  'domain',
];
function useCompanyAddons() {
  const abortController = useRef();
  const { enqueueErrorSnackbar } = useNotifier();
  const navigate = useNavigate();

  const handleExpiredAuthError = (err) => {
    const isExpired = !!err.response?.data?.expiredAuth;
    if (isExpired) {
      removeValue('user');
      enqueueErrorSnackbar('Session expired');
      navigate('/login');
      return;
    }
    throw err;
  };

  useEffect(() => () => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);

  const loadAddons = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    return axios.get(urls.getCompanyAddons(), {
      signal: abortController.current.signal,
    })
      .then((response) => response.data)
      .catch(handleExpiredAuthError);
  }, []);

  const createAddon = useCallback((attributes) => {
    const preparedAttributes = _.pickBy(
      _.pick(attributes, pluginFields),
      (value) => !_.isEmpty(value),
    );

    return axios.post(urls.getCompanyAddons(), preparedAttributes)
      .catch(handleExpiredAuthError);
  }, []);

  const updateAddon = useCallback((id, attributes) => {
    const preparedAttributes = _.pick(attributes, pluginFields);

    return axios.patch(urls.getCompanyAddons(id), preparedAttributes)
      .catch(handleExpiredAuthError);
  }, []);

  // eslint-disable-next-line arrow-body-style
  const handleRemove = useCallback((id) => {
    return axios.delete(urls.getCompanyAddons(id))
      .catch(handleExpiredAuthError);
  }, []);

  return [
    loadAddons,
    createAddon,
    updateAddon,
    handleRemove,
  ];
}

export default useCompanyAddons;
