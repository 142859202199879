import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { If } from 'react-if';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';

import useMobileDialog from '../hooks/useMobileDialog';

const progressSize = 24;

const ConfirmButtonProgress = styled(CircularProgress)(
  () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(progressSize / 2),
    marginLeft: -(progressSize / 2),
  }),
);

const ConfirmButton = styled(Button)(
  () => ({
    position: 'relative',
  }),
);

const Content = styled(DialogContent)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: 400,
    },
  }),
);

function ConfirmationDialog(props) {
  const {
    onCancel,
    onSubmit,
    children,
    open,
    confirmVariant,
    confirmLabel,
    cancelVariant,
    cancelLabel,
    title,
    className,
    inProgress,
    submitDisabled,
    ...dialogProps
  } = props;

  const fullScreen = useMobileDialog();

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onCancel}
      {...dialogProps}
    >
      <If condition={title}>
        <DialogTitle>{title}</DialogTitle>
      </If>
      <If condition={children}>
        <Content>
          {children}
        </Content>
      </If>
      <DialogActions>
        <Button
          color="secondary"
          variant={cancelVariant}
          onClick={onCancel}
          disabled={inProgress}
        >
          {cancelLabel || 'Cancel'}
        </Button>
        <ConfirmButton
          color="primary"
          data-testid="confirm-button"
          disabled={inProgress || submitDisabled}
          variant={confirmVariant}
          onClick={onSubmit}
        >
          {confirmLabel || 'Confirm'}
          <If condition={inProgress}>
            <ConfirmButtonProgress size={progressSize} />
          </If>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  confirmVariant: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelVariant: PropTypes.string,
  cancelLabel: PropTypes.string,
  inProgress: PropTypes.bool,
  children: PropTypes.any,
  submitDisabled: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  title: '',
  className: '',
  confirmVariant: 'text',
  confirmLabel: undefined,
  cancelVariant: 'text',
  cancelLabel: undefined,
  inProgress: false,
  children: undefined,
  submitDisabled: false,
};

export default ConfirmationDialog;
