import React from 'react';
import {
  Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { get as registryGet } from '../lib/appRegistry';

function RequireFlag({ children }) {
  const { developersPortalEnabled } = registryGet('config');

  if (!developersPortalEnabled) {
    return <Navigate to="/" />;
  }

  return children;
}

export default RequireFlag;

RequireFlag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
