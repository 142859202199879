import React from 'react';
import {
  Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { getValue } from '../utils/storage';

function RequireAuth({ children }) {
  if (!getValue('user')) {
    return <Navigate to="/" />;
  }

  return children;
}

export default RequireAuth;

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
