import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Collapse,
} from '@mui/material';
import { If } from 'react-if';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import useIsMobileOrTablet from '../hooks/useMobileOrTablet';

const colors = {
  danger: {
    key: 'danger',
    color: red[500],
  },
};

function MenuItem(props) {
  const {
    onClick,
    leftIcon,
    rightIcon,
    text,
    disabled,
    menuItems,
    color,
    iconComponent,
    ...otherProps
  } = props;

  const isMobileOrTablet = useIsMobileOrTablet();
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const toggleSubMenu = useCallback(
    () => setSubMenuOpen(!isSubMenuOpen),
    [isSubMenuOpen, setSubMenuOpen],
  );
  const IconComponent = iconComponent || ListItemIcon;

  if (_.isEmpty(menuItems)) {
    return (
      <ListItem
        button={!disabled}
        onClick={onClick}
        {...otherProps}
      >
        <If condition={!!leftIcon}>
          <IconComponent>
            {leftIcon}
          </IconComponent>
        </If>
        <ListItemText primary={text} />
        <If condition={!!rightIcon}>
          {rightIcon}
        </If>
      </ListItem>

    );
  }

  return (
    <>
      <ListItem
        button
        onClick={toggleSubMenu}
        {...otherProps}
      >
        <If condition={!!leftIcon}>
          <IconComponent>
            {leftIcon}
          </IconComponent>
        </If>
        <ListItemText primary={text} />
        <If condition={!!rightIcon}>
          {rightIcon}
        </If>
        {isSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse key="subMenu" in={isSubMenuOpen} timeout="auto" unmountOnExit>
        <List dense={!isMobileOrTablet} component="div" disablePadding>
          {menuItems}
        </List>
      </Collapse>
    </>
  );
}

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  disabled: PropTypes.bool,
  menuItems: PropTypes.array,
  color: PropTypes.oneOf([colors.danger.key]),
  iconComponent: PropTypes.element,
};

MenuItem.defaultProps = {
  color: undefined,
  onClick: undefined,
  leftIcon: undefined,
  rightIcon: undefined,
  disabled: false,
  menuItems: [],
  iconComponent: undefined,
};

export default MenuItem;
