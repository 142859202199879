/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Link,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { If, Then } from 'react-if';
import { useSnackbar } from 'notistack';

import requestWithAuth from '../../utils/requestWithAuth';
import urls from '../../shared/urls';

const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '5%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10vh',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
  },
  header: {
    color: 'grey',
    margin: theme.spacing(2),
    marginTop: 0,
  },
  cardInner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardInfo: {
    maxWidth: '300px',
    minHeigth: '100px',
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  singleLinkWrapper: {
    justifyContent: 'center',
  },
  marginWrapper: {
    margin: theme.spacing(2),
  },
  mainArea: {
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWidth: '90%',
    },
  },
}));

function CheckEmailMessage() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;

  const [email, setEmail] = useState('');
  const [info, setInfo] = useState({
    title: 'Регистрация пройдена',
    content: 'Подтверждение аккаунта отправлено к Вам на почту',
  });

  useEffect(() => {
    if (state) {
      if (state.email) {
        setEmail(state.email);
      }
      if (state.info) {
        setInfo(state.info);
      }
    }
  }, [state]);

  const onReSendHandler = () => {
    requestWithAuth.post(urls.resend, { email }).then(() => {
      enqueueSnackbar('Подтверждение выслано на почту', {
        variant: 'success',
        autoHideDuration: 5000,
        action: (
          <IconButton
            onClick={() => {
              closeSnackbar();
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      });
    })
      .catch((err) => {
        let message = '';

        if (!err.request) {
          message = 'Что-то пошло не так, но мы уже получили об этом информацию и работаем над этим';
        } else if (!err.response) {
          message = 'Возникла проблема с обработкой вашего запроса. Проверьте подключение к интернету.';
        } else if (err.response.data?.message) {
          message = err.response.data?.message;
        } else {
          message = 'Произошла ошибка при активации аккаунта';
        }

        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 5000,
          action: (
            <IconButton
              onClick={() => {
                closeSnackbar();
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        });
      });
  };
  return (
    <Box
      className={classes.mainBoxWrapper}
    >
      <div className={classes.mainArea}>
        <Card className={classes.cardInner}>
          <CardContent className={classes.cardInfo}>
            <div className={classes.header}>
              <Typography variant="h6">
                {info.title}
              </Typography>
            </div>
            <Divider />
            <div className={classes.marginWrapper}>
              <Typography variant="h7">
                {info.content}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Divider />
        <div className={classes.linksWrapper}>
          <Link
            href="/"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            Обратно в developers
          </Link>
          <If condition={email}>
            <Then>
              <Link
                href="#"
                onClick={onReSendHandler}
                style={{
                  color: 'grey',
                  textDecorationColor: 'grey',
                }}
              >
                Выслать письмо повторно
              </Link>
            </Then>
          </If>
          <Link
            href="/login"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            На страницу авторизации
          </Link>
        </div>
      </div>
    </Box>
  );
}

export default CheckEmailMessage;
