import React from 'react';
import {
  Typography,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Box,
  Paper,
} from '@mui/material';
import hljs from 'highlight.js';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import Footer from '../../Footer';
import {
  Wrapper,
  SectionTitleWrapper,
  StyledTable,
  TableCellContent,
  Caption,
} from '../../../lib/Components/StyledComponents';
import facadeBadgesImg from '../../../images/facade_badges.png';
import greenBadgeImg from '../../../images/green_badge.png';
import redBadgeImg from '../../../images/red_badge.png';
import orangeBadgeImg from '../../../images/orange_badge.png';
import defaultBadgeImg from '../../../images/default_badge.png';
import badgeUrlImg from '../../../images/badge_url.png';

const example = `'card_facade_badges': async (context) => {
  const timerStartTime = await context.getData('card', 'private', 'timerStartTime');

  if (!timerStartTime) {
    return {
      text: '🔴timer is off',
      color: 'red',
    }
  }

  return {
    text: '🟢 timer is on',
    color: 'green',
  }
},`;

function CardFacadeBadges() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <div>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title="card_facade_badges" hash="#header" />
          </SectionTitleWrapper>
          <Typography gutterBottom sx={{ marginBotton: 2 }}>
            A text chip with an optional icon, which will be displayed on the facades of cards.
          </Typography>
          <img width="300" src={facadeBadgesImg} alt="iframe in the card" />
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            <b>Function argument</b>
            {' '}
            - an object that provides all available functions of the web SDK.
          </Typography>
          <Typography gutterBottom sx={{ marginTop: 2, marginBottom: 2 }}>
            <b>Return value</b>
            {' '}
            - the function should return array object with the following keys:
          </Typography>
          <Paper elevation={0}>
            <TableContainer>
              <StyledTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCellContent>Key</TableCellContent>
                    <TableCellContent>Type</TableCellContent>
                    <TableCellContent>Description</TableCellContent>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellContent>
                      <b>text</b>
                      <Caption
                        variant="caption"
                        color="error"
                      >
                        required
                      </Caption>
                    </TableCellContent>
                    <TableCellContent>string</TableCellContent>
                    <TableCellContent>Chip text</TableCellContent>
                  </TableRow>
                  <TableRow>
                    <TableCellContent>
                      color
                    </TableCellContent>
                    <TableCellContent>enum</TableCellContent>
                    <TableCellContent>
                      Chip color
                      <div>
                        <Snippet removeGutters inline>green</Snippet>
                        <Box sx={{ marginTop: 1 }}>
                          <img width="200" src={greenBadgeImg} alt="green badge" />
                        </Box>
                      </div>
                      <div>
                        <Snippet removeGutters inline>red</Snippet>
                        <Box sx={{ marginTop: 1 }}>
                          <img width="200" src={redBadgeImg} alt="red badge" />
                        </Box>
                      </div>
                      <div>
                        <Snippet removeGutters inline>orange</Snippet>
                        <Box sx={{ marginTop: 1 }}>
                          <img width="200" src={orangeBadgeImg} alt="orange badge" />
                        </Box>
                      </div>
                      <div>
                        <Snippet removeGutters inline>undefined(default color)</Snippet>
                        <Box sx={{ marginTop: 1 }}>
                          <img width="250" src={defaultBadgeImg} alt="default badge" />
                        </Box>
                      </div>
                    </TableCellContent>
                  </TableRow>
                  <TableRow>
                    <TableCellContent>
                      <b>icon</b>
                    </TableCellContent>
                    <TableCellContent>string</TableCellContent>
                    <TableCellContent>
                      The URL of the icon that will be displayed to the left of the text.
                      <Box sx={{ marginTop: 1 }}>
                        <img width="250" src={badgeUrlImg} alt="badge with url" />
                      </Box>
                    </TableCellContent>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>

          <Typography gutterBottom sx={{ marginTop: 2 }}>
            Example :
          </Typography>
          <Snippet>
            <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
          </Snippet>
        </div>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default CardFacadeBadges;
