import { useCallback, useState, useEffect } from 'react';

export default (open = false, element = undefined) => {
  const [isOpen, togglePopover] = useState(open);
  const [anchorEl, setAnchorEl] = useState(element);

  useEffect(() => {
    if (element !== null) {
      setAnchorEl(element);
    }
  }, [element]);

  const onClose = useCallback(
    () => {
      togglePopover(false);
      setAnchorEl(null);
    },
    [togglePopover, setAnchorEl],
  );

  const onOpen = useCallback(
    ({ currentTarget }) => {
      togglePopover(true);
      setAnchorEl(currentTarget);
    },
    [togglePopover, setAnchorEl],
  );

  return [isOpen, anchorEl, onOpen, onClose];
};
