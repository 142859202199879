import React from 'react';
import {
  Typography,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Footer from '../../Footer';
import {
  Wrapper,
  SectionTitleWrapper,
  StyledTable,
  TableCellContent,
  TableHeader,
  Caption,
} from '../../../lib/Components/StyledComponents';
import successSnackbarImg from '../../../images/success_snackbar.png';
import infoSnackbarImg from '../../../images/info_snackbar.png';
import warningSnackbarImg from '../../../images/warning_snackbar.png';
import errorSnackbarImg from '../../../images/error_snackbar.png';

function ShowSnackbar() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <div>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title="showSnackbar" hash="#header" />
          </SectionTitleWrapper>
          <Typography gutterBottom>
            <b>context.showSnackbar(text, snackbarLevel)</b>
            {' '}
            Triggers the appearance of a snackbar (alert). The snackbar includes a close button and disappears automatically after 5 seconds.
          </Typography>
          <Paper elevation={0}>
            <TableHeader>
              <SectionTitle title="Arguments" hash="#arguments" />
            </TableHeader>
            <TableContainer>
              <StyledTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCellContent>argument</TableCellContent>
                    <TableCellContent>Type</TableCellContent>
                    <TableCellContent>Description</TableCellContent>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellContent>
                      text
                      <Caption
                        variant="caption"
                        color="error"
                      >
                        required
                      </Caption>
                    </TableCellContent>
                    <TableCellContent>string</TableCellContent>
                    <TableCellContent>Snackbar text</TableCellContent>
                  </TableRow>
                  <TableRow>
                    <TableCellContent>
                      snackbarLevel
                    </TableCellContent>
                    <TableCellContent>enum</TableCellContent>
                    <TableCellContent>
                      Snackbar Style. default: info
                      <Typography>success: </Typography>
                      <img width="250" src={successSnackbarImg} alt="success snackbar" />
                      <Typography sx={{ marginTop: 1 }}>info: </Typography>
                      <img width="250" src={infoSnackbarImg} alt="info snackbar" />
                      <Typography sx={{ marginTop: 1 }}>warning: </Typography>
                      <img width="250" src={warningSnackbarImg} alt="warning snackbar" />
                      <Typography sx={{ marginTop: 1 }}>error: </Typography>
                      <img width="250" src={errorSnackbarImg} alt="error snackbar" />
                    </TableCellContent>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            <b>Return value</b>
            {' '}
            -
            {' '}
            Promise
          </Typography>
        </div>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default ShowSnackbar;
