import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import {
  Switch, Case, Default, If,
} from 'react-if';
import { styled } from '@mui/material/styles';
import axiosForCancelToken from 'axios';
import {
  CircularProgress, Typography, Button, Container,
} from '@mui/material';
import Header from '../Header';
import Footer from '../Footer';
import UserAddonDialog from './UserAddonDialog';
import useCompanyAddons from './useCompanyAddons';
import useNotifier from '../hooks/useNotifier';
import AddonsTable from './AddonsTable';

import ConfirmDialog from '../Dialogs/confirmDialog';

const Wrapper = styled('div')(
  ({ theme }) => ({
    paddingTop: theme.spacing(2),
  }),
);

const Title = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
  }),
);
const CreateButton = styled(Button)(
  ({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
  }),
);

const LoaderContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: theme.spacing(1),
  }),
);

const TableContainer = styled('div')(
  () => ({
    overflow: 'auto',
    height: 'calc(100vh - 200px)',
    display: 'flex',
    flexDirection: 'column',
  }),
);

function AddonsList() {
  const [isAddonsLoading, setIsAddonsLoading] = useState(false);
  const [addons, setAddons] = useState([]);
  const [isAddonDialogOpen, setIsAddonDialogOpen] = useState(false);
  const [currentAddonId, setCurrentAddonId] = useState(null);
  const { enqueueErrorSnackbar } = useNotifier();
  const [addonIdToRemove, setAddonIdToRemove] = useState(null);
  const [isAddonRemoving, setIsAddonRemoving] = useState(false);

  const [
    handleLoadAddons,
    handleCreateAddon,
    handleUpdateAddon,
    handleRemoveAddon,
  ] = useCompanyAddons();

  const loadAddons = useCallback(() => {
    setIsAddonsLoading(true);
    handleLoadAddons()
      .then((currentPlugins) => {
        setAddons(_.orderBy(currentPlugins, 'created', 'desc'));
        setIsAddonsLoading(false);
      })
      .catch((err) => {
        setIsAddonsLoading(false);

        if (axiosForCancelToken.isCancel(err)) {
          return;
        }

        if (!err.request) {
          enqueueErrorSnackbar('Something went wrong, but we have already received information about it and working on it');
        } else if (!err.response) {
          enqueueErrorSnackbar('There is a problem processing your request. Please check your internet connection.');
        } else if (_.startsWith(err.response.status, 5)) {
          enqueueErrorSnackbar('An error occurred while loading addons.');
        } else {
          enqueueErrorSnackbar('An error occurred while loading addons.');
        }
      });
  }, [handleLoadAddons, enqueueErrorSnackbar]);

  useEffect(() => {
    loadAddons();
  }, []);

  const onCreateClick = () => {
    setIsAddonDialogOpen(true);
  };

  const onPluginDialogClose = useCallback(() => {
    setCurrentAddonId(null);
    setIsAddonDialogOpen(false);
  }, []);

  const onAddonClick = (addonId) => {
    setCurrentAddonId(addonId);
    setIsAddonDialogOpen(true);
  };

  const handleAddonSave = useCallback((attributes, id = null) => {
    if (!id) {
      return handleCreateAddon(attributes)
        .then(({ data: createdAddon }) => setAddons((prevAddons) => [{
          ...createdAddon,
          domain: attributes.domain,
        }, ...prevAddons]));
    }

    return handleUpdateAddon(id, attributes)
      .then(({ data: updatedAddon }) => {
        setAddons((prevAddons) => {
          const updatedAddons = [];

          _.forEach(prevAddons, (processingAddon) => {
            if (processingAddon.id !== updatedAddon.id) {
              updatedAddons.push(processingAddon);
              return;
            }
            const updatedAddonData = {
              ...processingAddon,
              ...updatedAddon,
            };
            if (attributes.domain) {
              updatedAddonData.domain = attributes.domain;
            }

            updatedAddons.push(updatedAddonData);
          });
          return updatedAddons;
        });
      });
  }, [handleCreateAddon, handleUpdateAddon]);

  const handleAddonRemoveClick = useCallback((id) => {
    setAddonIdToRemove(id);
  }, []);

  const handleConfirmRemove = () => {
    setIsAddonRemoving(true);

    return handleRemoveAddon(addonIdToRemove)
      .then(() => {
        setIsAddonRemoving(false);
        setAddonIdToRemove(null);
        setAddons(_.reject(addons, ['id', addonIdToRemove]));
      })
      .catch((err) => {
        if (!err.request) {
          enqueueErrorSnackbar('Something went wrong, but we have already received information about it and working on it');
        } else if (!err.response) {
          enqueueErrorSnackbar('There is a problem processing your request. Please check your internet connection.');
        } else if (_.startsWith(err.response.status, 4)) {
          enqueueErrorSnackbar(_.get(err, 'response.data.message') || err.response.data);
        } else if (_.startsWith(err.response.status, 5)) {
          enqueueErrorSnackbar('An error occurred while saving addon data.');
        } else {
          enqueueErrorSnackbar('An error occurred while saving addon data.');
        }
      });
  };

  return (
    <>
      <Header />
      <Wrapper>
        <Container maxWidth="md">
          <Title variant="h6">
            My Addons
          </Title>
          <CreateButton
            color="primary"
            variant="outlined"
            disabled={isAddonsLoading}
            onClick={onCreateClick}
          >
            Create New Addon
          </CreateButton>
        </Container>

        <TableContainer>
          <Container maxWidth="md">
            <Switch>
              <Case condition={isAddonsLoading && _.isEmpty(addons)}>
                <LoaderContainer>
                  <Typography variant="body2" color="textSecondary">
                    Loading ...
                  </Typography>
                  <CircularProgress size={18} />
                </LoaderContainer>
              </Case>
              <Case condition={_.isEmpty(addons)}>
                <Typography variant="body2">
                  Empty addons list
                </Typography>
              </Case>
              <Default>
                <AddonsTable
                  addons={addons}
                  onAddonClick={onAddonClick}
                  onAddonRemove={handleAddonRemoveClick}
                />
              </Default>
            </Switch>
          </Container>
          <Footer />
        </TableContainer>
      </Wrapper>
      <If condition={isAddonDialogOpen}>
        <UserAddonDialog
          addon={currentAddonId ? _.find(addons, { id: currentAddonId }) : null}
          onClose={onPluginDialogClose}
          onSave={handleAddonSave}
        />
      </If>

      <If condition={addonIdToRemove}>
        <ConfirmDialog
          open
          onSubmit={handleConfirmRemove}
          inProgress={isAddonRemoving}
          onCancel={() => setAddonIdToRemove(null)}
          confirmLabel="Remove"
        >
          {`The «${_.find(addons, { id: addonIdToRemove })?.name}» addon will become unavailable after removal.
             Are you sure you want to remove this addon?`}
        </ConfirmDialog>
      </If>

    </>
  );
}

export default AddonsList;
