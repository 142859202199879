import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, Navigate, useOutletContext } from 'react-router-dom';
import {
  Typography,
  Divider,
  Box,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { If, Then } from 'react-if';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import NativeScrollContainer from '../NativeScrollContainer';
import { ApiContext } from '../../context';
import highlightJson from '../../utils/highlightJson';
import PayloadDescription from './PayloadDescription';
import WithFooter from '../WithFooter';

const SnippetWrapper = styled(Paper)(
  ({ theme }) => ({
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(2),
    backgroundColor: grey[100],
    overflow: 'auto',
  }),
);
const Container = styled(Box)({
  display: 'flex',
});
const OuterWrapper = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const InnerWrapper = styled(Box)(
  ({ theme }) => ({
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  }),
);
const Title = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const StyledDivider = styled(Divider)({
  marginTop: '20px',
});
const Description = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const PayloadDescriptionWrapper = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(3),
  }),
);
const Header = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const PayloadWrapper = styled('div')(
  ({ theme }) => ({
    paddingLeft: theme.spacing(2),
  }),
);
const PayloadContent = styled(Box)({
  fontSize: '12px',
});
function Snippet({ children, inline }) {
  return (
    <SnippetWrapper
      elevation={0}
      component={inline ? 'span' : undefined}
    >
      <NativeScrollContainer>
        <Typography
          color="text.secondary"
          component={inline ? 'span' : 'div'}
        >
          {children}
        </Typography>
      </NativeScrollContainer>

    </SnippetWrapper>
  );
}

Snippet.propTypes = {
  children: PropTypes.any.isRequired,
  inline: PropTypes.bool,
};

Snippet.defaultProps = {
  inline: false,
};

function Info() {
  const { entity, operation } = useParams();
  const { api } = useOutletContext();
  const { keydEntities } = useContext(ApiContext)[api];

  const currentOperation = _.get(keydEntities, [
    entity,
    'operations',
    operation,
  ]);
  if (!currentOperation) {
    return <Navigate to="/external-webhooks" />;
  }

  const {
    name, description, payloadExample, payloadDescription,
  } = currentOperation;
  return (

    <Container>
      <OuterWrapper>
        <NativeScrollContainer className="info">
          <InnerWrapper>
            <WithFooter>
              <Title
                variant="h4"
                component="h4"
              >
                {name.toUpperCase()}
              </Title>
              <StyledDivider />
              <Description>{description}</Description>
              <If condition={payloadDescription}>
                <Then>
                  <PayloadDescriptionWrapper>
                    <PayloadDescription data={payloadDescription} />
                  </PayloadDescriptionWrapper>
                </Then>
              </If>
              <Header variant="h6">Payload Example</Header>
              <PayloadWrapper>
                <Snippet>
                  <PayloadContent component="pre" dangerouslySetInnerHTML={{ __html: highlightJson(payloadExample) }} />
                </Snippet>
              </PayloadWrapper>
            </WithFooter>

          </InnerWrapper>
        </NativeScrollContainer>
      </OuterWrapper>
    </Container>
  );
}

export default Info;
