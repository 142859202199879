import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DismissAction({
  onClick,
}) {
  return (
    <IconButton onClick={onClick}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

DismissAction.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function useNotifier() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMemo(() => {
    const dismissAction = (key) => <DismissAction onClick={() => closeSnackbar(key)} />;

    return {
      enqueueSnackbar,
      closeSnackbar: (key) => closeSnackbar(key),
      enqueueErrorSnackbar: (message, options) => enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
        action: dismissAction,
        ...options,
      }),
      enqueueInfoSnackbar: (message, options) => enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: 5000,
        action: dismissAction,
        ...options,
      }),
      enqueueWarningSnackbar: (message, options) => enqueueSnackbar(message, {
        variant: 'warning',
        autoHideDuration: 5000,
        action: dismissAction,
        ...options,
      }),
      enqueueSuccessSnackbar: (message, options) => enqueueSnackbar(message, {
        variant: 'success',
        autoHideDuration: 5000,
        action: dismissAction,
        ...options,
      }),
    };
  }, [enqueueSnackbar, closeSnackbar]);
}

export default useNotifier;
